
import Footer from "./footer"
import React, { useState,useRef } from 'react';

import emailjs from '@emailjs/browser';
import ScrollToTop from "./scrolltotop"
import 'bootstrap/dist/css/bootstrap.min.css';
import {Controller,Scene} from "react-scrollmagic";
import {Link} from "react-router-dom";
import {ReactComponent as Messagesvg} from "../components/images/icons/message-icon.svg";
import {useTranslation} from "react-i18next";
function Seo(){
    const form = useRef();
    const [formSuccess, setFormSuccess] = useState(null)
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_8dl5akj', 'template_1j6aq9d', form.current, '1WDwycUbK2atdOhNg')
      .then((result) => {
          console.log(result.text);
          document.getElementById("seo-form").reset();
          setFormSuccess(true)
      }, (error) => {
          console.log(error.text);
          setFormSuccess(false)
      });
  };
    const { t } = useTranslation();
    return(
        <div className="overflow-hidden mt-5" >
            <div id="triggerhead" className="row justify-content-center mb-5 row">
        <Controller>
        <Scene duration={0} classToggle="h-head-scrolled"   triggerElement="#" reverse={false} >
          {(progress, event) => (
          <div className="h-head col-11  ">
          <h1 className="font-big-story text-uppercase"style={{textAlign:"center"}}>{t("Contact us")} </h1>
          </div>
          )}
        </Scene>
        </Controller>
        </div>
<div className="container " style={{ paddingTop:"2vh", paddingBottom:"6vh"}}>

    <div className="row justify-content-center justify-content-sm-center" >
    <div className=" row col-12 col-md-5  parag-zil mb-3">
    
        <div  className="col-12">
          <h4 className="maze-color text-uppercase mx-4">Analisi Seo gratuita,</h4>
        <p className="text-white mx-4">Invia una richiesta descrivendo il tuo progetto e ti ricontatteremo il prima possibile mostrandoti la soluzione giusta per te.<br></br>
        <br></br> </p> 
        <span className="ourcolor m-4 ">Hai gia un sito web?</span> 
        <p className="text-white mx-4">
        Inviaci il link ed eseguiremo un analisi SEO e un'analisi tecnica completa. Avrai la possibilitò di analizzare a fondo il tuo sito web e la sua ottimizzazione
        </p>
           
        </div>
       
        
        
        </div>
    <div className=" col-10 col-md-5 emailCard p-3">
        
        
        <form id="seo-form" ref={form} onSubmit={sendEmail}>
        <div className="row justify-content-center" >
        <div className="col-10">
   <input className="w-100 input-style "  type="text" name="user_name" placeholder={t("Name")}></input>
   </div>
 
   <div className="col-10">
   <input className="w-100 mt-4 input-style"  type="email" name="user_email" placeholder="Email"></input>
   </div>
   <div className="col-10">
   <input className="w-100 mt-4 input-style"  type="text" name="user_website" placeholder="Sito web"></input>
   </div>
   <div className="col-10">
   <textarea className="w-100 mt-4 input-style"   rows={4}  type="text" name="user_message" placeholder={t("Talk about your project")}></textarea>
   </div>
   <div className="row justify-content-center">
    <button className="mailButton  mt-3 col-6" type="submit" value="Send">{t("Send")} </button>
   </div>
   </div>
        </form>
        {
     formSuccess  ? <div className="form-success"><h4  className="ourcolor form-success-h2">Grazie per la richiesta, la contatteremo il prima possibile</h4></div> : <div></div>
  }


   
    </div> 
    </div>

</div>

    
    </div>
    );
}
export default Seo;