import React, { useState, useEffect } from 'react';

import "./anteprimaWorks.css"
import mokupWb from "./images/Works/mokupWb.png"
import harmonielogo from "./images/Works/harmonielogo.png"
import uniqueProdotti from "./images/Works/unique-prodotti.png"
import ancoMarzio from "./images/Works/ancomarzio.jpeg"
import moody from "./images/Design/carousel/4.webp"
import depropris from "./images/Design/carousel/9.webp"
import matic from "./images/Design/carousel/7.webp"
import toc from "./images/Design/carousel/6.webp"
import girlpwr from "./images/Design/carousel/5.webp"

function AnteprimaWorks() {



    return (
        <div className="container worksBg ">
            








            <div className="row justify-content-center align-content-center ">
                <div className="col-12 col-md-6 d-md-flex align-items-center d-none">
                <div id="projectParag" >
                    <h1>Projects</h1>
                    <p>Poniamo massima cura in ogni dettaglio, unendo creatività e funzionalità per offrire
                        soluzioni su misura. Ogni progetto è pensato per soddisfare le esigenze dei nostri clienti 
                        e garantire esperienze uniche e innovative.
                    </p>
                    
                </div>
                </div>
                
                
                    <div id="containerImg" className="col-12 col-md-6 d-flex justify-content-end justify-content-md-center py-5">
                        
                        <div id="imgGrandeContainer" className=" ">
                            
                                <img id="imgGrande" src={mokupWb}  alt="Immagine Grande"  />
                            
                            
                          
                           
                        </div>
                        


                    </div>


                


            </div>

        </div>



    );
}
export default AnteprimaWorks;