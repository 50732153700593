
import "./components.css"
import { useState, useEffect } from "react"
import React from "react";


enum Phase {
    Typing,
    Pausing,
    Deleting,
}


const TYPING_INTERVAL = 150
const PAUSE_MS = 1000
const DELETING_INTERVAL = 50




const superpowers = ["Creatives.","Innovatives.", "Adaptables.", "Websailor."]

export const useTypedSuperpower = (superpowers: string[]) => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [phase, setPhase] = useState(Phase.Typing)
    const [typedSuperpower, setTypedSuperpower] = useState("")
    useEffect(() => {
        switch (phase) {
            case Phase.Typing: {
                const nextTypedSuperpower = superpowers[selectedIndex].slice(0, typedSuperpower.length + 1)

                if (nextTypedSuperpower === typedSuperpower) {
                    setPhase(Phase.Pausing)
                    return
                }
                const timeout = setTimeout(() => {
                    setTypedSuperpower(nextTypedSuperpower)
                }, TYPING_INTERVAL)
                return () => clearTimeout(timeout)
            }
            case Phase.Deleting: {


                if (!typedSuperpower) {
                    const nextIndex = selectedIndex + 1
                    setSelectedIndex (superpowers[nextIndex] ? nextIndex : 0)
                    setPhase(Phase.Typing)
                    return
                }
                const nextRemaining = superpowers[selectedIndex].slice(0, typedSuperpower.length - 1)
                const timeout = setTimeout(() => {
                    setTypedSuperpower(nextRemaining)
                },DELETING_INTERVAL)
                return () => clearTimeout(timeout)
            }
            case Phase.Pausing:
            default:
                const timeout = setTimeout(() => {
                    setPhase(Phase.Deleting)
                }, PAUSE_MS)
                return () => clearTimeout(timeout)
                
        }

        

    }, [superpowers, typedSuperpower,selectedIndex, phase])
    return typedSuperpower

}
export const Intro = () => {
    const superpower = useTypedSuperpower(superpowers)
    return (

<div className="introbox">
    <div className="DivII">
        <h1 className="introh"><span className="first-letter">W</span>e Are</h1>
        <h2 className="introh2"><span className="  blinking-cursor">{superpower}</span></h2>
        </div>
        </div>




    );

}

