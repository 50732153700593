import 'bootstrap/dist/css/bootstrap.min.css';

import Footer from "../components/footer"
import React from "react";
import { useTranslation } from "react-i18next"
import "./components.css"
import Icon1 from "./images/Social/creative.png"
import Icon2 from "./images/Social/digital-agency.png"
import Icon3 from "./images/Social/social-media.png"
import Contact2 from  "./Contact2"
import ScrollToTop  from "./scrolltotop"
import { HashLink as Link2 } from 'react-router-hash-link';

function Social() {
    const { t } = useTranslation();
    return (
        <div  className="overflow-hidden">
        
            <div className="container pt-5  mt-5 " style={{ paddingTop: "10%" }}>
                <div id="rowsocial1"className="row " >
                    <div id="titolosocial"className="col-12 col-md-6 " style={{textAlign:"center"}} >
                        <h2 className='col-12' style={{textAlign:"center"}}> Social Media Manager</h2>
                        <p className='p-2 mt-md-5' style={{textAlign:"justify"}}>Il Social Media Manger è una professione sempre più importante per il success odelle strategie di marketing.
                         La prima responsabilità di una Social Media Manager è di attuare una strategia di marketing sui social media; per farlo deve identificare
                          le buyer personas corrette e realistiche, in maniera specifica per ogni singolo social e impostare obiettivi definiti in modo da aumentare 
                          il tasso di conversione dei follower in clienti del tuo brand</p>
                               <Link2 to="/services/Social#moreSocial" className='button-2 m-1 text-black '> Scopri di più</Link2>
                          
                        </div>
                        <div id="imgsocial1" className='col-6 d-none d-md-block'>
                    </div>

                </div>
                <div id="rowsocial2" className="row mt-md-5 pt-3">
                    <div id="col1" className='col '>
<img className="iconSocial"src={Icon1}></img>
<p className="p-4">Social Media <br></br> Manager</p>
                    </div>
                    <div id="col1" className='col '>
<img className="iconSocial"src={Icon2}></img>
<p className="p-4">Content <br></br> Creator</p>
                    </div>
                    
                    <div id="col1" className='col '>
                    <div id="moreSocial"></div>
<img className="iconSocial"src={Icon3}></img>
<p className="p-4">Digital <br></br> marketing</p>
                    </div>
                    
                    

                </div>
                
               

            </div>
<div>
    <Contact2/>
</div>
            <div>
                <ScrollToTop/>
                <Footer />
            </div>
           
            
        </div>


    );
}
export default Social;