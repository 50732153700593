import React, { useState, useEffect } from 'react';
import '../index.css';
import '../App.css';
import ClipLoader from 'react-spinners/ClipLoader';
import 'bootstrap/dist/css/bootstrap.min.css';

import ScrollToTop from "../components/scrolltotop"
import Footer from "../components/footer"
import Video from "../components/images/video/modelloultimo.mkv"
import { Intro } from "../components/Intro.tsx";
import { Controller, Scene } from "react-scrollmagic"
import { Link } from "react-router-dom"
import { HashLink as Link2 } from 'react-router-hash-link';
import Marquee from "react-fast-marquee";
import { Canvas } from "@react-three/fiber"
import { Center, OrbitControls } from "@react-three/drei";
import CookieConsent, { Cookies } from "react-cookie-consent";
import Team from "../components/team"
import { useTranslation } from "react-i18next";

import ServicesBoxs from "../components/services-boxs"
import Contact2 from "../components/Contact2";
import AnteprimaWorks from "../components/anteprimaWorks.js"

import Blog from "../components/images/Dev/blog.png";
import Custom from "../components/images/Dev/custom.png";
import Ecommerce from "../components/images/Dev/ecommerce.png";
import Hosting from "../components/images/Dev/hosting.png";
import Mobile from "../components/images/Dev/mobile.png";
import Vetrina from "../components/images/Dev/vetrina.png";
import Slider from '../components/Slider.js';
import Necessita from '../components/necessita.js';







const getVideoSrc = width => {
  if (width >= 720) return Video;
  if (width <= 720) return null;

};

const Videosrc = props => {
  const src = getVideoSrc(window.innerWidth);
  return (
    <div id="videoposition" >
      <video poster='../components/images/scrivaniamobile.webp' autoPlay={true} loop={true} playsInline={true} muted={true}  >
        <source src={src} type='video/mp4' />
      </video>



    </div>
  );
};







function App() {
  const scrollToContact = () => {
    const contactSection = document.getElementById('contact');
    const yOffset = 0; // Offset per tenere conto di eventuali header fissi
    const y = contactSection.getBoundingClientRect().top + window.pageYOffset + yOffset;

    const start = window.pageYOffset;
    const distance = y - start;
    const duration = 500; // Durata in millisecondi
    let startTime = null;
    const animateScroll = (currentTime) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1);
      const run = start + distance * progress;
      window.scrollTo(0, run);
      if (timeElapsed < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  };
  const [loadingInProgress, setLoading] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    setLoading(false);
  }, []);
  return (

    <div id="#triggerintro" className="">
      {loadingInProgress ? (
        <div className="loader-container ">
          <ClipLoader color={'#fff'} loading={loadingInProgress} size={150} />
        </div>
      ) : (
        <div>
         


         






     






          {//homepage desktop
          }
          <div id="containerIniziale" className="container overflow-hidden  d-flex align-items-center  " >
            


              

                

                  <div className='row justify-content-center'>
                    <div className='col-12 row '>
                    <div className='col-12 col-md-10 col-lg-8'>
                    <h1 className='websailorsHome  '><span className='ourcolor'>Hai un'idea digitale?</span><span className='text-white'> Noi la realizziamo.</span></h1>
                    </div>
                    <div className='col-12 col-md-10 col-lg-8'>
                      <p className=" " >
                        Siamo qui per creare insieme a te una presenza online che faccia davvero la differenza.
                         Dal sito web alle strategie di marketing, ti offriamo soluzioni su misura per raggiungere i tuoi obiettivi
                      </p>
                    </div>
                    <div className='d-flex  '>
                   
                      <button onClick={scrollToContact} className="buttonMain  ">
                       
                        Inizia il tuo progetto
                      </button>
                      
                    </div>
                    </div>
                    
                    
                    

                  </div>





                




              
              






            
          </div>
          {//end desktop
          }
{/*
          <div id="services" className="">
            <Marquee speed={60} gradient={false} style={{}}>


              <Link to="/#services">
                <h3 className="">
                  <span className="mx-4 ourcolorBasic">Siti vetrina</span>
                  <span className="mx-4 text-white">E-commerce</span>
                  <span className="mx-4 ourcolorBasic">Ottimizzazione mobile</span>
                  <span className="mx-4 text-white">Spot pubblicitari</span>
                  <span className="mx-4 ourcolorBasic">Branding</span>
                  <span className="mx-4 text-white">PhotoEditing</span>
                  <span className="mx-4 ourcolorBasic">Seo</span>
                  <span className="mx-4 text-white">Design</span>
                  <span className="mx-4 ourcolorBasic">VideoEditing</span>
                  <span className="mx-4 text-white">Meta advertising</span>
                  <span className="mx-4 ourcolorBasic">Web3.0</span>
                  <span className="mx-4 text-white">Nft</span>
                  <span className="mx-4 ourcolorBasic">3d modelling</span>
                  <span className="mx-4 text-white">PhotoEditing</span>
                  <span className="mx-4 ourcolorBasic">Design</span>
                  <span className="mx-4 text-white">VideoEditing</span>
                  <span className="mx-4 ourcolorBasic">Web3.0</span>
                  <span className="mx-4 text-white">Nft</span>
                </h3>
              </Link>


            </Marquee>
          </div>
*/}
          <div>
            <Necessita/>
            </div>




{/*
<div style={{marginTop:"10%"}}>
  <Slider/>
</div>
*/}

<div style={{marginTop:"10%"}}>
  <AnteprimaWorks/>
</div>








         
          

          
          {/*<div id="team" className="overflow-hidden ">
        <Team />
        
      </div>
      */
          }

          <div id="contact" className="overflow-hidden">
            <Contact2 />
          </div>





          <div className="container" style={{ paddingTop: "5%" }}>

            <Footer />
          </div>







          <ScrollToTop />
          <CookieConsent
            location="bottom"
            buttonText="Accetta"
            buttonClasses="button-cookie"
            cookieName="myAwesomeCookieName2"
            disableButtonStyles

            buttonStyle={{ padding: "10%", marginRight: "60px" }}
            style={{ background: "rgba(22, 22, 22, 0.8)", padding: "5%" }}


            expires={150}
          >
            <div className="row">
              <div className="col-9">
                <h5>
                  Questo sito utilizza cookie per migliorare l'esperienza dell'utente.
                  Per ulteriori informazioni consulta <Link className="ourcolor" to='/cookiepolicy'>Cookie Policy</Link> e <Link className="ourcolor" to='/privacypolicy'>Privacy Policy</Link>
                </h5>
              </div>


            </div>


          </CookieConsent>
        </div>

      )}
    </div>



  );


}
export default App;