import 'bootstrap/dist/css/bootstrap.min.css';

import Footer from "../components/footer"
import React from "react";
import { useTranslation } from "react-i18next"
import "../components/components.css"

import Contact2 from  "../components/Contact2"
import ScrollToTop  from "../components/scrolltotop"
import { HashLink as Link2 } from 'react-router-hash-link';

function PageNotFound() {
    const { t } = useTranslation();
    return (
        <div  className="overflow-hidden" style={{paddingTop:"20%"}}>
        <div className='row justify-content-center'><h1 className='text-white' style={{}}>Error 404 <br></br>Pagina non trovata, <br></br><a href="#" className='ourcolor'>torna indietro</a></h1></div>
            
<div>
    <Contact2/>
</div>
            <div>
                <ScrollToTop/>
                <Footer />
            </div>
           
            
        </div>


    );
}
export default PageNotFound;