import 'bootstrap/dist/css/bootstrap.min.css';
import {Controller,Scene} from "react-scrollmagic";
import ServicesBoxs from "../components/services-boxs"
import Footer from "../components/footer"
import React from "react";
import {Link} from "react-router-dom"
import { useTranslation } from 'react-i18next';

 
function  Web3(){
    const { t } = useTranslation();
    return(
        <div id="trigger">
            <div style={{paddingTop:"13%"}}>
               
            </div>
            <div className=' row justify-content-center mt-5 ' >
                <div className=" col-9 ">
                <p style={{fontSize:"150%"}}><Link className='color-Link' to="/Services">{t("Services")} </Link><span style={{fontSize:"130%"}}>/</span> Web 3.0</p>
                <Controller>
                    <Scene duration={0} classToggle="fullstacked" triggerElement="#triggerintro"  >
                      
                        <div className="card-fullstack  row">
                        

                                <p className="text-white col-12 col-md-6">
                               

                                </p>
                                <h3>{t("After studing the purely speculative aspect of crypto several years ago")},{t("we decided to explore the useful aspects in these digital ecosystem")},{t("learning to develop solidity-based Dapps")}.</h3>
                        </div>
                    </Scene>
                </Controller>
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>


    );
}
export default Web3;