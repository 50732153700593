
import ReactDOM from 'react-dom/client';
import './index.css';



import './App.css';

import App from './pages/App';


import PageNotFound from "./pages/PageNotFound";
import Fixed from "./components/fixed-text";
import Fullstack from "./components/fullstack";
import Social from "./components/Social";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import Design from "./components/Design";
import Navbar from "./components/navbar"
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import VideoEditing from "./components/Videoedit";
import Web3 from "./components/web3";
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route} from "react-router-dom"
import ScrollToTop from "./components/scrolltotop"
import i18n from "i18next";
import {initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend';



i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en','it'],
    fallbackLng: "it",
    detection: {
      order: ['cookie','htmlTag',  'localStorage','path', 'subdomain'],
      caches: ["cookie"],
    },
    backend:{
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
    react:{ useSuspense: false},

  
  });
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    
    

    <BrowserRouter>
      <Navbar/>
      <Fixed/>
      <Routes>
        <Route path="/" element={<App/>}/>
        <Route path="/cookiepolicy" element={<CookiePolicy/>}/>
        <Route path="/privacypolicy" element={<PrivacyPolicy/>}/>
        <Route path="/services/Fullstack" element={<Fullstack/>}/>
        <Route path="/services/VideoEditing" element={<VideoEditing/>}/>
        <Route path="/services/Web3" element={<Web3/>}/>
        <Route path="/services/Social" element={<Social/>}/>
        <Route path="/services/Design" element={<Design/>}/>
        <Route path="*" element={<PageNotFound/>} />
      </Routes>
      </BrowserRouter>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
