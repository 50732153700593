import 'bootstrap/dist/css/bootstrap.min.css';
import { Controller, Scene } from "react-scrollmagic";
import ServicesBoxs from "../components/services-boxs"
import Footer from "../components/footer"
import React from "react";
import { Link } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.css";
import Slider from "./Slider";
import { useTransition } from 'react';

import "./components.css";
import modified1 from './images/web/DSC_0113_2.jpg';
import original1 from './images/web/bosco.jpg';
import Ab from './ab';
import ScrollToTop from "../components/scrolltotop"
import { useTranslation } from 'react-i18next';



function VideoEditing() {
  const { t } = useTranslation();
  const slider = {
    original: {
        url: original1,
        title: 'After'
    },
    modified: {
        url: modified1,
        title: 'Before'
    }
};
  return (

    <div className='overflow-hidden'>
      
      
       



<div className="parallaxEdit">
  <div id='txt-edit' className=" row justify-content-center"  >
    <h1 className="ourcolor nomeEditing col-11 col-md-12">Aleksander
    </h1>
    <p style={{color:"fff"}} className="col-12" >Videomaker | Photographer | Content Creator</p>
  </div>
</div>
<div className='row justify-content-center 'style={{paddingTop:"3%"}}  >
           <div id='pedit' className='col-11  row justify-content-end'>
                <h1 style={{fontSize:"300%"}}>Editing</h1>
                <p className="mt-3" >
                  {t("Photography")}, {t("videography and audio are the essentials to speack to your customers")}.
                  {t("And here we are")}, {t("to give you the possibility to offer the comunication you need with your clients and to express yourself in the best way possible")}.
                 </p>
                 <button className="button-2 col-5 col-sm-4 col-md-3 mt-2">Contattami</button>
                 </div>
                <div className=' col-11 mt-5' >
              
            <Ab {...slider}/>
            </div>
            
            </div>
<div>

</div>
{/*<div className="row justify-content-center" style={{marginTop:"10%",marginBottom:"10%"}}>
  
  
<div className="col-11  " style={{paddingTop:"3%"}}>
<h1 className='ourcolor'>Editing</h1>
                <p >
                  {t("Photography")}, {t("videography and audio are the essentials to speack to your customers")}.
                  {t("And here we are")}, {t("to give you the possibility to offer the comunication you need with your clients and to express yourself in the best way possible")}.
                 </p>
  </div>
  <div className=' col-11 mt-5 '>
  <Slider/>
</div>
  </div>*/}

      <div>
        <Footer />
      </div>
      <ScrollToTop/>
    </div>


  );
}
export default VideoEditing;