import 'bootstrap/dist/css/bootstrap.min.css';
import { Controller, Scene } from "react-scrollmagic";
import Footer from "../components/footer"
import React from "react";
import { Link } from "react-router-dom"
import "./components.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';
import  foto1 from "./images/Design/carousel/1.webp"
import  foto2 from "./images/Design/carousel/2.webp"
import  foto3 from "./images/Design/carousel/3.webp"
import prevIcon from "./images/Design/carousel/freccia1.png"
import ScrollToTop from "../components/scrolltotop"
import {useTranslation} from "react-i18next";
import Contact2 from  "./Contact2"
import { HashLink as Link2 } from 'react-router-hash-link';


function Design() {
    const { t } = useTranslation();
    
    return (
        <div className=" overflow-hidden  " style={{ paddingTop: "3%", fontFamily: 'Montserrat' }}>




            {/* MOBILE PAGE  MOBILE PAGE  MOBILE PAGE  MOBILE PAGE  MOBILE PAGE */}



            <div className="d-block d-sm-none">
                
        <div id="rowmobile1" className="row justify-content-end">
 <div className="col-8 " style={{paddingTop:"22%"}}>
    <div className="container ">
      <p className='' style={{marginTop:"50%"}}>{t("Studying architecture stimulated us to look for new ways to express our  creativity")}, 
      {t("and being computer graphics geeks since the age of eight in both the Windows and macOS worlds")}, 
      {t("we enjoyed working together right away")}.
      </p>  
      <Link2 to="/services/Design#more"className="text-black button-2">View more</Link2>
    </div>

 </div>
 <div id="more"></div>
 <div id="scrivaniaMobile"className="col-12">

 </div>

 <div id="simbolofogliemb" className="col-12 row justify-content-center">
 <p className='col-10 row justify-content-end '>{t("Study abroad experiences allowed us to explore new programs")}
 {t("and different approaches while improving our workflow in terms of flexibility and thinking outside the box")}.
                            <Link2 to="/services/Design#contactDesign"className=" text-blackbutton-2 col-5 mt-3">Contact us</Link2>
                            </p>
                           
                            
                           
                            <p className='col-10 mt-5 pt-4 row justify-content-end'>
 {t("Working together not only allows us to divide the workload but also to be able to find the most effective solution in less time")}.
 {t("We can also always rely on each other's critical advice")}
 <Link2 to="/#team" className="button-2 text-black col-5 mt-3">Our Team</Link2>
 </p>
 
 </div>
 <div id="titolo2mb"className="col-12 mt-2">

 </div>
 <div className="col-12 row justify-content-center">
    <div className='col-10 mt-1'>
    <p>{t("We love to keep up with innovative visual communication methods")}, {t("our work ranges from logo design for companies")}, {t("3D modeling")}, {t("music album cover")}, {t("page layout")}, {t("UX and UI design")}, {t("to the total care of a brand's visual identity")}.</p>
    </div>
    
 

 </div>
 
 
        </div>
        <div id="carmb">
        <Carousel >
                
                <Carousel.Item>
                  
                      <div className="row justify-content-center p-5" style={{minHeight:"350px"}}>
                  <div id="car1" className="carouselDesign d-block col-4 slideritem m-2 ">
                      </div>
                      <div id="car2" className="carouselDesign d-block col-4 slideritem m-2">
                      </div>
                      <div id="car3" className=" carouselDesign d-block col-4 slideritem m-2">
                        
                      </div>
                      <div id="car4" className="carouselDesign d-block col-4 slideritem m-2">
                      </div>
                     
                      
                      </div>
          
                 
                  
                  
                </Carousel.Item>
                <Carousel.Item>
                  
                  <div className="row justify-content-center p-5" style={{minHeight:"350px"}}>
              <div id="car5" className="carouselDesign d-block col-4 slideritem m-2 ">
                  </div>
                  <div id="car6" className="carouselDesign d-block col-4 slideritem m-2">
                  </div>
                  <div id="car7" className="carouselDesign d-block col-4 slideritem m-2">
                    
                  </div>
                  <div id="car8" className=" carouselDesign d-block col-4 slideritem m-2">
                  </div>
                 
                  
                  </div>
      
             
              
              
            </Carousel.Item>
            <Carousel.Item>
                  
                      <div className="row justify-content-center p-5" style={{minHeight:"350px"}}>
                  <div id="car9" className="d-block col-4 slideritem m-2 ">
                      </div>
                      <div id="car10" className=" carouselDesign d-block col-4 slideritem m-2">
                      </div>
                      <div id="car11" className="carouselDesign d-block col-4 slideritem m-2">
                        
                      </div>
                      <div id="car12" className="carouselDesign d-block col-4 slideritem m-2">
                      </div>
                     
                      
                      </div>
          
                 
                  
                  
                </Carousel.Item>
                <Carousel.Item>
                  
                  <div className="row justify-content-center p-5" style={{minHeight:"350px"}}>
              <div id="car13" className="d-block col-4 slideritem m-2 ">
                  </div>
                  <div id="car14" className=" carouselDesign d-block col-4 slideritem m-2">
                  </div>
                  <div id="car7" className="carouselDesign d-block col-4 slideritem m-2">
                    
                  </div>
                  <div id="car8" className="carouselDesign d-block col-4 slideritem m-2">
                  </div>
                 
                  
                  </div>
      
             
              
              
            </Carousel.Item>
              </Carousel>
        </div>
        
            </div>



            {/* MEDIUM PAGE   MEDIUM PAGE   MEDIUM PAGE   MEDIUM PAGE   MEDIUM PAGE   MEDIUM PAGE */}



            <div className='d-none d-sm-block d-lg-none'>
            <div id="rowmmedium1" className="row justify-content-end">
 <div className="col-8 " style={{paddingTop:"25%"}}>
    <div className="container">
      <p className='row justify-content-end'>{t("Studying architecture stimulated us to look for new ways to express our  creativity")}, 
      {t("and being computer graphics geeks since the age of eight in both the Windows and macOS worlds")}, 
      {t("we enjoyed working together right away")}.
      <Link2 to="/services/Design#ScrollDesign" className="button-2 col-4 text-black">View more</Link2>
      </p>  
    </div>

 </div>
 <div id="scrivaniaMedium"className="col-12">

 </div>
 <div id="simbolofogliemd" className="col-12 row justify-content-center">
 <p className='col-10 row justify-content-end'>

                            {t("Study abroad experiences allowed us to explore new programs and different approaches while improving our workflow in terms of flexibility and thinking outside the box")}.<br></br>
                            <Link2 to="/services/Design#contactDesign" className="text-black button-2 col-4">Contact us</Link2>
                            </p>
                            <p className='col-10 mt-5 pt-4 row justify-content-end'>
 {t("Working together not only allows us to divide the workload but also to be able to find the most effective solution in less time. We can also always rely on each other's critical advice")}.
 <div id="ScrollDesign"></div>
 <Link2 to="/#team" className="text-black button-2 col-4">Our Team</Link2>
 </p>
 </div>
 
 <div id="titolo2md"className="col-12 mt-2">

 </div>
 <div className="col-12 row justify-content-center">
    <div className='col-10 mt-1'>
    <p>{t("We love to keep up with innovative visual communication methods")}, {t("our work ranges from logo design for companies")}, {t("3D modeling")}, {t("music album cover")}, {t("page layout")}, {t("UX and UI design")}, {t("to the total care of a brand's visual identity")}.</p>
    </div>
    
 

 </div>
 
 
        </div>
        <div id="carmb">
        <Carousel >
                
                <Carousel.Item>
                  
                      <div className="row justify-content-center p-5" style={{minHeight:"350px"}}>
                  <div id="car1" className="d-block col-4 slideritem m-2 ">
                      </div>
                      <div id="car2" className="carouselDesign d-block col-4 slideritem m-2">
                      </div>
                      <div id="car3" className="carouselDesign d-block col-4 slideritem m-2">
                        
                      </div>
                      <div id="car4" className="carouselDesign d-block col-4 slideritem m-2">
                      </div>
                     
                      
                      </div>
          
                 
                  
                  
                </Carousel.Item>
                <Carousel.Item>
                  
                  <div className="row justify-content-center p-5" style={{minHeight:"350px"}}>
              <div id="car5" className="carouselDesign d-block col-4 slideritem m-2 ">
                  </div>
                  <div id="car6" className="carouselDesign d-block col-4 slideritem m-2">
                  </div>
                  <div id="car7" className="carouselDesign d-block col-4 slideritem m-2">
                    
                  </div>
                  <div id="car8" className="carouselDesign d-block col-4 slideritem m-2">
                  </div>
                 
                  
                  </div>
      
             
              
              
            </Carousel.Item>
            <Carousel.Item>
                  
                      <div className="row justify-content-center p-5" style={{minHeight:"350px"}}>
                  <div id="car1" className="carouselDesign d-block col-4 slideritem m-2 ">
                      </div>
                      <div id="car2" className="carouselDesign d-block col-4 slideritem m-2">
                      </div>
                      <div id="car3" className="carouselDesign d-block col-4 slideritem m-2">
                        
                      </div>
                      <div id="car1" className="carouselDesign d-block col-4 slideritem m-2">
                      </div>
                     
                      
                      </div>
          
                 
                  
                  
                </Carousel.Item>
              </Carousel>
        </div>
            </div>



            {/* DESKTOP PAGE   DESKTOP PAGE   DESKTOP PAGE   DESKTOP PAGE   DESKTOP PAGE   DESKTOP PAGE */}



            <div className="d-none d-lg-block">
            <div id="headDesign" className='row justify-content-between'>
                <div id="foglie1" className="col-3 ">

                </div>
                <div id="Titolo1Design" className="col-4">

                </div>
                <div id="Nube1" className="col-3">

                </div>
            </div>
            <div className='container'>
                <div className="row justify-content-end">
                    <div className="col-9 m-5  mt-0">
                        <p className='p-3 row justify-content-end'>
                            {t("Studying architecture stimulated us to look for new ways to express our  creativity")}, {t("and being computer graphics geeks since the age of eight in both the Windows and macOS worlds")}, {t("we enjoyed working together right away")}.
                            <Link2 to="/services/Design#ScrollDesign"className="text-black button-2 col-2 mt-3 text-black">View more</Link2>
                        </p>
                    </div>
                    <div className='col-1'></div>
                </div>
            </div>
            <div className=" container overflow-hidden">
                <div id="rowdesign" className='row'>
                    <div id="ScrivaniaDesign" className='col-4'>

                    </div>

                    <div className="col-8 row justify-content-between ">
                        <div className="col-9 " id="simboliDesign">

                        </div>

                        <div className='col-3' id="Nube2">

                        </div>
                        <div  className='col-9'>
                            <p className='pt-5 px-3 row justify-content-end' style={{textAlign:"justify"}}>
                            {t("Study abroad experiences allowed us to explore new programs")} {t("and different approaches while improving our workflow in terms of flexibility and thinking outside the box")}.<br></br>
                            <Link2 id="ScrollDesign"to="/services/Design#contactDesign"className="text-black button-2 col-4 mt-3">Contact us</Link2>
                             <br ></br>
                            {t("Working together not only allows us to divide the workload but also to be able to find the most effective solution in less time")}  {t("We can also always rely on each other's critical advice")}.
                            <Link2 to="/#team"  className="text-black button-2 col-3 mt-3">Our Team</Link2>
                            </p>
                        </div>
                        <div id="pianta" className='col-2'>

                        </div>
                    </div>
                    

                    
                </div>
                <div id="rowdesign2" className='row'>
                    <div id="Nube3" className="col-12">

                    </div>
                    <div id="TitoloDesign2" className='col-12 row justify-content-center ' >
                        <p className='col-12  mt-4' style={{textAlign:"justify"}}> {t("We love to keep up with innovative visual communication methods")}, {t("our work ranges from logo design for companies")}, {t("3D modeling")}, {t("music album cover")}, {t("page layout")}, {t("UX and UI design")}, {t("to the total care of a brand's visual identity")}.
                        </p>
                    </div>

                </div>


            </div>
            <div className='overflow-hidden'>
                <div id="rowdesign3" className=''>
                <Carousel >
                
      <Carousel.Item>
        <div className='container'>
            <div className="row justify-content-center p-5" style={{minHeight:"400px"}}>
        <div id="car1" className="carouselDesign d-block col-3 slideritem w-25">
            </div>
            <div id="car5" className="carouselDesign  d-block col-3 slideritem w-25">
            </div>
            <div id="car3" className="carouselDesign d-block col-3 slideritem w-25">
            </div>
            </div>

        </div>
        
        
      </Carousel.Item>
      <Carousel.Item>
        <div className='container'>
            <div className="row justify-content-center p-5" style={{minHeight:"400px"}}>
        <div id="car2" className="carouselDesign d-block col-3 slideritem w-25">
            </div>
            <div id="car4" className="carouselDesign d-block col-3 slideritem w-25">
            </div>
            
            </div>

        </div>
        
        
      </Carousel.Item>
      <Carousel.Item>
        <div className='container'>
            <div className="row justify-content-center p-5" style={{minHeight:"400px"}}>
        <div id="car8" className="carouselDesign d-block col-3 slideritem w-25">
            </div>
            <div id="car9" className=" carouselDesign d-block col-3 slideritem w-25">
            </div>
            <div id="car6" className="carouselDesign d-block col-3 slideritem w-25">
            </div>
            
            </div>

        </div>
        
        
      </Carousel.Item>
      <Carousel.Item>
        <div className='container'>
            <div className="row justify-content-center p-5" style={{minHeight:"400px"}}>
        <div id="car7" className="carouselDesign d-block col-3 slideritem w-25">
            </div>
            <div id="car14" className="carouselDesign d-block col-3 slideritem w-25">
            </div>
            <div id="car10" className=" carouselDesign d-block col-3 slideritem w-25">
            </div>
            </div>

        </div>
        
        
      </Carousel.Item>
      <Carousel.Item>
        <div className='container'>
            <div className="row justify-content-center p-5" style={{minHeight:"400px"}}>
        <div id="car12" className="carouselDesign d-block col-3 slideritem w-25">
            </div>
            <div id="car11" className="carouselDesign d-block col-3 slideritem w-25">
            </div>
            <div id="car13" className=" carouselDesign d-block col-3 slideritem w-25">
            </div>
            </div>

        </div>
        
        
      </Carousel.Item>
    </Carousel>
                </div>
            </div>
            </div>
            


            
            
           <div id="contactDesign">
            <Contact2/>
           </div>
            <div>
                <Footer />
                <ScrollToTop/>
            </div>
        </div>


    );
}
export default Design;