import Footer from "../components/footer"
import ScrollToTop from "../components/scrolltotop"
import 'bootstrap/dist/css/bootstrap.min.css';
function PrivacyPolicy() {

    return (
        <div className="overflow-hidden " style={{paddingTop:"10%", paddingLeft:"6%", paddingRight:"6%"}}>
            <h2 className="ourcolor">Privacy policy</h2><br></br>
            <p>Questa Privacy Policy è resa ai sensi dell’art. 13 del Regolamento Europeo n. 679/2016 e si applica esclusivamente
                a tutti i Dati raccolti attraverso il Sito web websailorsdev.com e i suoi sottodomini. La presente Privacy Policy,
                unitamente alla Cookie Policy, stabilisce le basi sulle quali verranno elaborati i Dati personali dell’Utente.

            </p>
            <h2 className="ourcolor mt-5 mb-4">Titolare del Trattamento</h2>
            <p>Il Titolare del Trattamento dei Dati raccolti da questo Sito web è Alessandro Malaguti,
                membro del team Websailors.<br></br> Email: w3bsailors@gmail.com
            </p>

            <h2 className="ourcolor mt-5 mb-4">Modalità di Trattamento dei Dati Personali</h2>
            <p>I Dati Personali forniti o acquisiti saranno oggetto di trattamento improntato ai principi di correttezza,
                liceità, trasparenza e di tutela della riservatezza ai sensi delle vigenti normative. Il titolare tratta i
                dati personali degli utenti adottando le opportune misure di sicurezza volte ad impedire l’accesso, la divulgazione,
                la modifica o la distruzione non autorizzate dei dati personali. Il Trattamento viene effettuato mediante strumenti
                informatici e/o telematici,con modalità organizzative e con logiche strettamente correlate alle finalità indicate.
            </p>

            <h2 className="ourcolor mt-5 mb-4">Oggetto Privacy Policy</h2>
            <p>La presente informativa riguarda il trattamento relativo al conferimento, alla raccolta ed alle successive operazioni
                eseguite sui dati personali degli utenti del sito web mediante la navigazione nelle sue pagine e, soprattutto,
                mediante gli strumenti di richiesta di informazioni e di conferimento di dati personali a mezzo messaggi
                di posta elettronica, form contatti, eventuali procedure di registrazione/accesso area riservata, iscrizione
                newsletter, l’utilizzo di servizi e/o applicazioni, il compimento di operazioni simili ed analoghe.
                La presente policy privacy si applica solo ed esclusivamente alle attività online del presente sito websailorsdev.com, ed è
                valida per i visitatori e gli utenti del sito stesso. Quindi non si applica alle informazioni raccolte tramite canali diversi dal
                presente sito web. In ottemperanza degli obblighi derivanti dalla normativa nazionale e comunitaria in materia di tutela dei dati personali,
                il presente sito rispetta e tutela la riservatezza dei visitatori e degli utenti.
            </p>

            <h2 className="ourcolor mt-5 mb-4">Quali dati personali raccogliamo e perché li raccogliamo</h2>
            <p>Come tutti i siti web anche websailorsdev.com fa uso di log files/plugin di terze parti, nei quali vengono conservate informazioni raccolte in maniera automatizzata durante le visite degli utenti. Le informazioni raccolte possono essere le seguenti:<br></br>
                •	indirizzo internet protocol (IP);<br></br>
                •	tipo di browser e parametri del dispositivo usato per connettersi al sito;<br></br>
                •	nome dell’internet service provider (ISP);<br></br>
                •	data e orario di visita;<br></br>
                •	pagina web di provenienza del visitatore (referral) e di uscita;<br></br>
                •	eventualmente il numero di click.<br></br>
                Le suddette informazioni sono trattate in forma automatizzata e raccolte in forma esclusivamente aggregata al fine di verificare il corretto funzionamento del sito.
                Nessuna di queste informazioni è correlata alla persona fisica-utente di websaiolorsdev.com, e non ne consentono in alcun modo l’identificazione.
            </p>
            <h4 className="ourcolor mt-5 mb-4">Media</h4>
            <p>Se carichi immagini sul sito web, dovresti evitare di caricare immagini che includono i dati di posizione incorporati (EXIF GPS). I visitatori del sito web possono scaricare
                ed estrarre qualsiasi dato sulla posizione dalle immagini sul sito web.</p>
            <h4 className="ourcolor">Modulo di contatto</h4>
            <p>Tutti i dati raccolto attraverso il modulo contatto verranno utilizzati solo ed esclusivamente per lo scopo principale,
                cioè rispondere alla richiesta di aiuto o di consulenza da parte dell’utente. Questi dati non verranno mai utilizzati
                a scopi commerciali, di promozione o altro.</p>
            <h4 className="ourcolor">Cookie</h4>
            <p>Se lasci un commento sul nostro sito, puoi scegliere di salvare il tuo nome, indirizzo email e sito web nei cookie. Sono usati per la tua comodità in modo che tu non debba inserire nuovamente i tuoi dati quando lasci un altro commento. Questi cookie dureranno per un anno.
                Se hai un account e accedi a questo sito, verrà impostato un cookie temporaneo per determinare se il tuo browser accetta i cookie. Questo cookie non contiene dati personali e viene eliminato quando chiudi il browser.
                Quando effettui l’accesso, verranno impostati diversi cookie per salvare le tue informazioni di accesso e le tue opzioni di visualizzazione dello schermo. I cookie di accesso durano due giorni mentre i cookie per le opzioni dello schermo durano un anno. Se selezioni “Ricordami”, il tuo accesso persisterà per due settimane. Se esci dal tuo account, i cookie di accesso verranno rimossi.
                Se modifichi o pubblichi un articolo, un cookie aggiuntivo verrà salvato nel tuo browser. Questo cookie non include dati personali, ma indica semplicemente l’ID dell’articolo appena modificato. Scade dopo 1 giorno.
                Utilizzando websailorsdev.com il visitatore acconsente espressamente all’uso dei cookies, per tale ragione ti invito a leggere in modo dettagliato la pagina cookie law.
            </p>
            <h4 className="ourcolor">Contenuto incorporato da altri siti web</h4>
            <p>Gli articoli su questo sito possono includere contenuti incorporati (ad esempio video, immagini, articoli, ecc.). I contenuti incorporati da altri siti web si comportano esattamente allo stesso modo come se il visitatore avesse visitato l’altro sito web.
Questi siti web possono raccogliere dati su di te, usare cookie, integrare ulteriori tracciamenti di terze parti e monitorare l’interazione con quel contenuto incorporato, incluso il tracciamento della tua interazione con il contenuto incorporato se hai un account e hai effettuato l’accesso a quel sito web.
Per tanto ti invitiamo a leggere qui sotto i social network e o i siti web collegati a questo sito e a fianco di ciascuno trovi la privacy policy relativa. Non solo alcuni di questi progetti web sono utilizzati da noi per incorporare contenuti pubblicati esclusivamente all’interno delle loro pagine:<br></br>
•	Facebook, leggi la privacy policy di Facebook<br></br>
•	Twitter, leggi la privacy policy di Twitter<br></br>
•	Linkedin, leggi la privacy policy di Linkedin<br></br>
•	Google+, leggi la privacy policy di Google+<br></br>
•	Instagram, leggi la privacy policy si Instagram<br></br>
•	Telegram, leggi la privacy policy di Telegram<br></br>
</p>
<h4 className="ourcolor">Destinatari dei dati personali</h4>
<p>I dati personali raccolti da questo sito potranno essere trattati da soggetti coinvolti nell’organizzazione del sito (vecchi e nuovi collaboratori e amministratori di sistema) o da personale esterno (come fornitori di servizi tecnici terzi e hosting provider) di volta in volta nominate, se necessario, Responsabili del trattamento a norma dell’art. 4 n. 8 GDPR che definisce come “responsabile del trattamento” la persona fisica o giuridica, l’autorità pubblica, il servizio o altro organismo che tratta dati personali per conto del titolare del trattamento.
In ogni caso ogni utente potrà sempre richiedere al Titolare del trattamento l’elenco aggiornato dei Responsabili del trattamento con le modalità specificate nella sezione relativa ai diritti dell’utente, inviando una mail a w3bsailors@gmail.com
Ad eccezione di quanto indicato precedentemente, i dati personali dell’utente non vengono comunicati a terzi salvo:<br></br>
•	l’utente abbia rilasciato il proprio consenso espresso alla comunicazione;<br></br>
•	la comunicazione sia necessaria per fornire il servizio o il prodotto richiesto dall’utente;<br></br>
</p>
<h4 className="ourcolor">Periodo di conservazione</h4>
<p>I dati personali verranno trattati e conservati per il tempo strettamente necessario per la realizzazione della finalità per cui sono stati raccolti.
In particolare per quello che riguarda le richieste di informazioni, preventivi o consulenze varie, i dati raccolti saranno conservati per il tempo necessario a fornire le informazioni richieste dall’utente.
Se lasci un commento, il commento e i relativi metadati vengono conservati a tempo indeterminato. È così che possiamo riconoscere e approvare automaticamente eventuali commenti successivi invece di tenerli in una coda di moderazione.
Per gli utenti che si registrano sul nostro sito web (se presenti), memorizziamo anche le informazioni personali che forniscono nel loro profilo utente. Tutti gli utenti possono vedere, modificare o cancellare le loro informazioni personali in qualsiasi momento (eccetto il loro nome utente che non possono cambiare). Gli amministratori del sito web possono anche vedere e modificare queste informazioni.
</p>
<h4 className="ourcolor">Con chi condivido i tuoi dati</h4>
<p>Con nessuno, il team WebSailors è l’unico amministratore e gestore di questo sito.</p>
<h4 className="ourcolor">Quali diritti hai sui tuoi dati</h4>
<p>Se hai un account su questo sito, o hai lasciato commenti, puoi richiedere di ricevere un file esportato dal sito con i dati personali che abbiamo su di te, compresi i dati che ci hai fornito. Puoi anche richiedere che cancelliamo tutti i dati personali che ti riguardano. Questo non include i dati che siamo obbligati a conservare per scopi amministrativi, legali o di sicurezza.</p>
            <h2 className="ourcolor">Diritto di accesso</h2>
            <p>L’interessato ha il diritto di chiedere al Titolare del trattamento se sia in corso un trattamento di dati personali che lo riguardano e in caso affermativo di ottenere l’accesso agli stessi e le seguenti informazioni:<br></br>
1. L’interessato ha diritto di ottenere la conferma dell’esistenza o meno di dati personali che lo riguardano, anche se non ancora registrati, e la loro comunicazione in forma intelligibile.<br></br>
2. L’interessato ha diritto di ottenere l’indicazione:<br></br>
•	dell’origine dei dati personali;<br></br>
•	delle finalità e modalità del trattamento<br></br>;
•	della logica applicata in caso di trattamento effettuato con l’ausilio di strumenti elettronici;
degli estremi identificativi del titolare, dei responsabili e del rappresentante designato ai sensi dell’articolo 5, comma 2;<br></br>
•	dei soggetti o delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono venirne a conoscenza in qualità di rappresentante designato nel territorio dello Stato, di responsabili o incaricati.
3. L’interessato ha diritto di ottenere:<br></br>
•	l’aggiornamento, la rettificazione ovvero, quando vi ha interesse, l’integrazione dei dati;<br></br>
•	la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o successivamente trattati;<br></br>
•	l’attestazione che le operazioni indicate nei primi due punti sono state portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o comporta un impiego di mezzi manifestamente sproporzionato rispetto al diritto tutelato.
4. L’interessato ha diritto di opporsi, in tutto o in parte:<br></br>
•	per motivi legittimi al trattamento dei dati personali che lo riguardano, ancorché pertinenti allo scopo della raccolta;<br></br>
•	al trattamento di dati personali che lo riguardano a fini di invio di materiale pubblicitario o di vendita diretta o per il compimento di ricerche di mercato o di comunicazione commerciale.<br></br>
5. l’esistenza del diritto dell’interessato di chiedere al titolare del trattamento la rettifica o la cancellazione dei dati personali o la limitazione del trattamento dei dati personali che lo<br></br>
6. L’interessato ha diritto di conoscere l’esistenza di un processo decisionale automatizzato, compresa la profilazione di cui all’articolo 22, paragrafi 1 e 4, e, almeno in tali casi, informazioni significative sulla logica utilizzata, nonché l’importanza e le conseguenze previste di tale trattamento per l’interessato.<br></br>
Qualora i dati personali siano trasferiti a un paese terzo o a un’organizzazione internazionale, l’interessato ha il diritto di essere informato dell’esistenza di garanzie adeguate ai sensi dell’articolo 46 relative al trasferimento.<br></br>
</p>
<h4 className="ourcolor">Il team WebSailors specifica che:</h4>
<p>Non comunicheranno i dati personali degli utenti a organizzazioni internazionali o a paesi terzi. L’interessato ha in ogni momento la possibilità di chiedere la rettifica o la cancellazione dei propri dati personali secondo le modalità indicate nella relativa sezione attraverso l’invio di una mail a w3bsailors@gmail.com
I dati personali non vengono raccolti con procedimenti completamente automatizzati, ma è sempre necessario il consenso dell’utente che inserisce i propri dati nei form predisposti sul sito.
</p>
<h4 className="ourcolor">Diritto alla cancellazione e diritto di rettifica</h4>
<p>L’interessato ha il diritto di ottenere dal titolare del trattamento:<br></br>
•	la rettifica dei dati, qualora non siano corretti<br></br>
•	la cancellazione dei propri dati personali.<br></br>
Per l’esercizio di questo diritto è possibile inviare una richiesta scritta a w3bsailors@gmail.com<br></br>
. Il titolare del trattamento provvederà, senza ritardo, nel pieno rispetto dell’art. 17 del Regolamento europeo alla cancellazione richiesta.<br></br>
</p>
<h4 className="ourcolor">Il Team WebSailors specifica che:</h4>
<p>Il trattamento dei dati personali avviene esclusivamente previo rilascio del consenso dell’utente, unicamente per le finalità esplicitate di volta in volta al momento della raccolta dei dati personali. Qualora l’interessato ritenesse di esercitare il diritto di limitazione del trattamento potrà inviare una richiesta scritta a w3bsailors@gmail.com
Se hai un account su questo sito, o hai lasciato commenti, puoi richiedere di ricevere un file esportato dal sito con i dati personali che abbiamo su di te, compresi i dati che ci hai fornito. Puoi anche richiedere che cancelliamo tutti i dati personali che ti riguardano. Questo non include i dati che siamo obbligati a conservare per scopi amministrativi, legali o di sicurezza.
</p>
<h4 className="ourcolor">Diritto di proporre reclamo</h4>
<p>L’interessato ha il diritto di proporre reclamo presso l’Autorità di controllo.</p>
<h2 className="ourcolor">Titolare, responsabile e incaricati del trattamento</h2>
<p>Titolare e responsabile del trattamento ai sensi delle leggi vigenti è l’amministratore del sito, Alessandro Malaguti, contrattabile tramite la mail: alessandroprivato01@gmail.com</p>
<h5 className="ourcolor">Privacy Policy aggiornata ad Marzo/2023</h5>
            <div className='mt-5'>
                <Footer />
                <ScrollToTop/>
            </div>
        </div>


    );
}
export default PrivacyPolicy;