import 'bootstrap/dist/css/bootstrap.min.css';
import {Controller,Scene} from "react-scrollmagic";
import Footer from "../components/footer";
import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Blog from "./images/Dev/blog.png";
import Custom from "./images/Dev/custom.png";
import Ecommerce from "./images/Dev/ecommerce.png";
import Hosting from "./images/Dev/hosting.png";
import Mobile from "./images/Dev/mobile.png";
import Vetrina from "./images/Dev/vetrina.png";
import ScrollToTop from "../components/scrolltotop"
import { HashLink as Link2 } from 'react-router-hash-link';
import Seo from "./Contact2";

function Fullstack() {
    const { t } = useTranslation();
    return (
        <div className="overflow-hidden">
            <div className="container" style={{ paddingTop: "10%" }}>
                <div  className="row pt-5 pt-md-0">
                    
                    <div className="col-12 col-md-6 d-flex pt-5 pt-md-0 "  style={{ alignItems: "center", textAlign: "center" }}>
                        <div  >
                            <h1 className="ourcolor  " style={{ fontWeight: "700" }} >Dai un nuovo volto alla tua attività</h1>
                            <p style={{ fontWeight: "600" }}>Sviluppiamo siti web veloci, dinamici e ottimizzati per ogni browser e dispositivo</p>
                            <div className="m-2" style={{ textAlign: "end" }}>
                            <Link2 to="/services/Fullstack#Seo"><button style={{ fontWeight: "600" }} className="button-2 ">Parlaci del tuo progetto</button></Link2>
                               
                            
                               </div> 

                        </div>

                    </div>
                    <div id="dev1" className=" d-none d-md-block col-6">


                    </div>


                </div>
                <div  className="  container mt-5 pt-5">
                <div  className=" web-card row row-cols-1 row-cols-md-3 justify-content-center">
                   
                   <div className='col coldev mt-3'>
                   <img className="m-3 imgDev" src={Ecommerce}></img>
                   <h5 className="text-white" style={{fontWeight:"600"}}> Piattaforme eCommerce</h5>
                   <p>Piattaforme sviluppate per la vendita online di prodotti o servizi. </p>
                   </div>
                   <div className='col coldev mt-3'>
                   <img className="m-3 imgDev" src={Mobile}></img>
                   <h5 className="text-white" style={{fontWeight:"600"}}>Ottimizzazione per mobile</h5>
                   <p>Massima adattabilità su ogni dispositivo, attraverso una struttura del sito dinamica ed intelligente</p>
                   </div>
                   <div className='col coldev mt-3'>
                   <img className="m-3 imgDev" src={Vetrina}></img>
                   <h5 className="text-white" style={{fontWeight:"600"}}>Siti web</h5>
                   <p>Sviluppo siti web per privati e aziende, perfett iper accogliere il cliente in modo rapido ed intuitivo </p>
                   </div>
                   <div className='col coldev  mt-3'>
                       <img className="m-3 imgDev" src={Blog}></img>
                       <h5 className="text-white" style={{fontWeight:"600"}}>Massima conversione</h5>
                       <p>Ottieni la massima conversione con un sito web chiaro, veloce e ottimizzato per tutti browsers e devices</p>
                   </div>
                   <div className='col coldev  mt-3 '>
                   <img className="m-3 imgDev" src={Custom}></img>
                   <h5 className="text-white" style={{fontWeight:"600"}}>Siti custom avanzati</h5>
                   <p>Piattaforme web avanzate dedicati a progetti particolari. realizzate con Html, Css, JavaScript, React.</p>
                   </div>
                  
                   
                   
                   
               </div>
                </div>
                <div id="permette" className='pt-5 mt-5' >
                <h1 className="ourcolor" style={{fontWeight:"600"}}>Un sito web ti permette di:</h1>
                </div>
                <div className="row gx-5 row-cols-1 justify-content-center  mt-5 pt-5">
                    <div className='col-10 col-md-7 m-2 m-md-1 p-3 web-card2'>
                    <div className=' p-4  ' >
                        <h5 className="text-white mt-3" style={{fontWeight:"600"}}>Mostrare la tua attività a più persone</h5>
                        <p >Oggi Internet ci permette di mostrare il nostro lavoro in modo semplice, attraverso un sito web dedicato e veloce</p>
                        </div>
                    </div>
                    <div className='col-10 col-md-7 m-2 m-md-1 p-3 web-card2 '>
                    <div className=' p-4' >
                        <h5 className="text-white " style={{fontWeight:"600"}}>Vendere il tuo prodotto online</h5>
                        <p>Con una piattaforma di Ecommerce sarai in grado di vendere il tuo prodotto online, comodamente dietro il tuo pc, attraverso un interfaccia dedicata. </p>
                        </div>
                    </div>
                        <div className="col-10 col-md-7 m-2 m-md-1 p-3 web-card2">
                         <div className=' p-4 '>
                        <h5 className="text-white mt-3" style={{fontWeight:"600"}}>Massimizzare la conversione</h5>
                        <p>I nostri siti hanno lo scopo di essere intuitivi,veloci e dinamici e questi sono aspetti fondamentali nella conversione di un visitatore in un potenziale cliente.</p>
                        </div>   
                        </div>
                        
                       

                </div>
                <div id="Seo">
                    <Seo/>
                </div>
                <ScrollToTop/>
            </div>
            
            <div className='mt-5'>
                <Footer />
            </div>
        </div>


    );
}
export default Fullstack;