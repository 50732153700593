import Footer from "../components/footer"
import ScrollToTop from "../components/scrolltotop"

function CookiePolicy() {

    return (
        <div className="overflow-hidden" style={{ paddingTop: "10%", paddingLeft: "6%", paddingRight: "6%" }}>
            <h2 className="ourcolor">Cookie policy (UE)</h2><br></br>
            <p>Questa politica sui cookie è stata aggiornata l'ultima volta
                il 1 marzo 2023 e si applica ai cittadini e ai residenti permanenti
                legali dello Spazio Economico Europeo e della Svizzera

            </p>
            <h2 className="ourcolor mt-5 mb-4">1. Introduzione</h2>
            <p>Il nostro sito web websailorsdev.com (di seguito: "il sito web")
                utilizza i cookie e altre tecnologie correlate (per comodità tutte
                le tecnologie sono definite "cookie"). I cookie vengono anche inseriti
                da terze parti che abbiamo ingaggiato. Nel documento sottostante ti informiamo
                sull'uso dei cookie sul nostro sito web.
            </p>

            <h2 className="ourcolor mt-5 mb-4">2. Cosa sono i cookie?</h2>
            <p>I cookie sono dei semplici file spediti assieme alle pagine di questo sito e
                salvati dal tuo browser sul disco rigido del tuo computer o altri dispositivi.
                Le informazioni raccolte in essi possono venire rispediti ai nostri server oppure
                ai server di terze parti durante la prossima visita.
            </p>

            <h2 className="ourcolor mt-5 mb-4">3. Cosa sono gli script?</h2>
            <p>Uno script è un pezzo di codice usato per far funzionare correttamente ed interattivamente il nostro sito.
                Questo codice viene eseguito sui nostri server o sul tuo dispositivo.
            </p>
            <h2 className="ourcolor mt-5 mb-4">4. Cos'è un web beacon?</h2>
            <p>Un web beacon (o pixel tag) è un piccolo, invisibile pezzo di testo o
                immagine su un sito che viene usato per monitorare il traffico di un sito web. Per fare questo,
                diversi dati su di te vengono conservati utilizzando dei web beacon.
            </p>
            <h2 className="ourcolor mt-5 mb-4">5. Cookie</h2>
            <h5 className="ourcolor mt-5 mb-4">5.1 Cookie tecnici o funzionali</h5>
            <p>Alcuni cookie assicurano il corretto funzionamento del sito e che le tue preferenze rimangano valide.
                Piazzando cookie funzionali, rendiamo più facile per te visitare il nostro sito web. In questo modo
                non devi inserire ripetutamente le stesse informazioni quando visiti il nostro sito web, per esempio,
                l'oggetto rimane nel tuo carrello finché non hai pagato. Possiamo piazzare questi cookie senza il tuo consenso.
            </p>
            <h5 className="ourcolor mt-5 mb-4">5.3 Cookie di marketing/tracciamento</h5>
            <p>I cookie di marketing/tracciamento sono cookie o qualsiasi altra forma di memorizzazione locale,
                utilizzati per creare profili utente per visualizzare pubblicità o per tracciare l'utente su questo
                sito web o su diversi siti web per scopi di marketing simili.</p>
            <h5 className="ourcolor mt-5 mb-4">5.4 Social media</h5>
            <p>Sul nostro sito web abbiamo inserito contenuti di Facebook, Twitter, LinkedIn e Pinterest per promuovere pagine web (ad es. "mi piace", "pin") o condividerle (ad es. "tweet") su social network come Facebook, Twitter, LinkedIn e Pinterest. Questo contenuto è incorporato con codice derivato da Facebook, Twitter, LinkedIn e Pinterest e inserisce cookie. Questo contenuto potrebbe memorizzare ed elaborare alcune informazioni per la pubblicità personalizzata.
                Leggi l'informativa sulla privacy di questi social network (che possono cambiare regolarmente) per sapere cosa fanno con i tuoi dati (personali) che processano usando questi cookie. I dati ottenuti vengono anonimizzati quanto possibile. Facebook, Twitter, LinkedIn e Pinterest si trovano negli Stati Uniti.
            </p>
            <h4 className="ourcolor mt-5 mb-4">6. Cookie piazzati</h4>
            <h4 className="ourcolor mt-5 mb-4">Facebook</h4>
            <p className=" mt-5 mb-4">Consent to service facebook    Pubblicità/Tracciamento, Funzionale</p>
            <h4 className="ourcolor mt-5 mb-4">Twitter</h4>
            <p className=" mt-5 mb-4">Consent to service facebook    Pubblicità/Tracciamento, Funzionale</p>
            <h4 className="ourcolor mt-5 mb-4">LinkedIn</h4>
            <p className=" mt-5 mb-4">Consent to service linkedin   Pubblicità/Tracciamento, Funzionale</p>
               <h4 className="ourcolor mt-5 mb-4">Plugin traduzione automatica</h4> 
               <p>Funzionale</p>
               <h2 className="ourcolor mt-5 mb-4">7. Consenti</h2>
               <p className=" mt-5 mb-4">Quando visiti il sito web per la prima volta, noi mostreremo un popup con una spiegazione dei cookie.
                Appena clicchi su "Accetta", dai il permesso a noi di usare le categorie di cookie e plugin come descritto in questa
                 dichiarazione relativa ai popup e cookie. Puoi disabilitare i cookie attraverso il tuo browser, ma prendi in 
                 considerazione, che il nostro sito web potrebbe non funzionare più correttamente.</p>
                   <h2 className="ourcolor mt-5 mb-4">8. Abilitare/disabilitare e cancellazione dei cookie</h2>
               <p className=" mt-5 mb-4">Puoi usare il tuo browser per cancellare automaticamente o manualmente i cookie. È anche possibile specificare che
                determinati cookie non possono essere piazzati. Un'altra opzione è quella di modificare le impostazioni del tuo browser internet in modo da ricevere 
                un messaggio ogni volta che viene inserito un cookie. Per ulteriori informazioni su queste opzioni, consultare le istruzioni nella sezione Guida del tuo browser.
Ricorda che il nostro sito potrebbe non funzionare correttamente se tutti i cookie sono disabilitati. Se cancelli i cookie nel tuo browser, saranno riposti nuovamente dopo il tuo 
permesso quando visiterai nuovamente il nostro sito.
</p>
                   <h2 className="ourcolor mt-5 mb-4">9. I tuoi diritti con rispetto ai dati personali</h2>
               <p className=" mt-5 mb-4">Hai i seguenti diritti relativi ai tuoi dati personali:<br></br>
•	Hai il diritto di sapere quando i tuoi dati personali sono necessari, cosa succede ad essi, quanto a lungo verranno mantenuti.<br></br>
•	Diritto di accesso: hai il diritto ad accedere ai tuoi dati personali dei quali siamo a conoscenza.<br></br>
•	Diritto di rettifica: hai il diritto di completare, correggere, cancellare o bloccare i tuoi dati personali quando lo desideri.<br></br>
•	Se ci darai il consenso per elaborare i tuoi dati, hai il diritto di revocare questo consenso e di eliminare i tuoi dati personali.<br></br>
•	Diritto di trasferire i tuoi dati: hai il diritto di richiedere tutti i tuoi dati dal controllore e trasferirli tutti quanti ad un altro controllore.<br></br>
•	Diritto di opposizione: hai il diritto di opporti al trattamento dei tuoi dati. Noi rispetteremo questa scelta, a meno che non ci siano delle basi valide per trattarli.<br></br>
Per esercitare questi diritti, non esitate a contattarci. Si prega di fare riferimento ai dettagli di contatto in fondo a questa Cookie Policy. Se hai un reclamo su come gestiamo i tuoi dati,
 vorremmo sentirti, ma hai anche il diritto di presentare un reclamo all'autorità di vigilanza (l'Autorità per la Protezione dei Dati).
</p>
                   <h2 className="ourcolor mt-5 mb-4">10. Dettagli di contatto</h2>
               <p className=" mt-5 mb-4">Per domande e/o commenti riguardo la Cookie Policy e questa dichiarazione, per favore contattaci usando i seguenti dati di contatto:<br></br>
Alessandro Malaguti, WebSailors Team <br></br>
Guidonia Montecelio, 00012 Roma 
Italia <br></br>
Sito web: https://websailorsdev.com <br></br>
Email: w3bsailors@gmail.com <br></br>
Questa politica sui cookie è stata aggiornata il 1 marzo 2023. <br></br>
</p>
            
            <div className='mt-5'>
                <Footer />
                <ScrollToTop/>
            </div>
        </div>


    );
}
export default CookiePolicy;