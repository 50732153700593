import 'bootstrap/dist/css/bootstrap.min.css';
import {ReactComponent as GitHubsvg} from "./images/icons/github-icon.svg";
import {ReactComponent as Instasvg} from "./images/icons/instagram-icon.svg";
import {ReactComponent as Twittersvg} from "./images/icons/twitter-icon.svg";
import {ReactComponent as TikToksvg} from "./images/icons/tiktok-icon.svg";
import React from "react";
import {Controller,Scene} from "react-scrollmagic";
function  Fixed(){
    return(
        <div className="" >
            <div>
            <Controller>
                <Scene duration={0} classToggle="fixedtext-revealed" triggerElement="#">
               
<div className=" fixedtext text-white d-flex justify-content-center">
 
<a className="my-2 logo-fixed gelatineAnim" target="_blank" href="https://github.com/WebSailors"><GitHubsvg /></a>
                <a className="my-2 logo-fixed" target="_blank" href="https://www.instagram.com/w3bsailors/"><Instasvg /></a>
                <a className="my-2 logo-fixed" target="_blank" href="https://twitter.com/sailors_web"><Twittersvg /></a>
                <a className="my-2 logo-fixed" target="_blank" href="https://www.tiktok.com/@websailors"><TikToksvg /></a>

</div>
</Scene>

</Controller>
</div>
</div>

    );
}
export default Fixed;