import {Link} from "react-router-dom"

import {useTranslation} from "react-i18next";

function Services() {

  const { t } = useTranslation();
  return (
    <div className="overflow-hidden" >

      

        <div className="row justify-content-center ">
       
          <div  className="   col-11 col-sm-5 col-md-5 col-lg-5 col-xl-4 p-3" >
            
                
                  
                    <Link to="/services/Fullstack" className="">
                      <div className=" card-service web-bg bottomdiv"  >


                        <div className="p-1"  >
                          <h4 className="uppercase" style={{ fontSize: "120%", color: "#63fdd8" }}>
                            Sviluppo web
                          </h4>
                          <p className="col-8 col-sm-12" >
                          Sviluppo di siti web adatti ad ogni tipo di attività. {t("Development of Showcase sites")}
                          , Dapp, E-commerce {t("and more")}.

                          </p>
                          <p className="bottombutton"style={{ fontSize: "120%", color: "#63fdd8" }} >{t("View more")}</p>
                        </div>
                        
                      </div>
                    </Link>
                  
                
              

          </div>
          <div  className="col-11 col-sm-5 col-md-5 col-lg-5 col-xl-4 p-3 ">
            
                
                  <div className="  " >
                    <Link to="/services/Social">
                    <div className=" card-service social-bg bottomdiv"  >
                      <div className="container">
                        <h4 className="uppercase" style={{ fontSize: "120%", color: "#63fdd8" }}>
                         Gestione Social Media 
                        </h4>
                           <p>
                        Creazione, pubblicazione,  programmazione post, ricerca trend del momento, strategia comunicativa
                        
                        </p>
                        <p  className="bottombutton"style={{ fontSize: "120%", color: "#63fdd8",  }} >{t("View more")}</p>
                        
                      </div>
                    </div>
                    </Link>
                  </div>
             

          </div>
          
          
          <div  className="col-11 col-sm-5 col-md-5 col-lg-5 col-xl-4  p-3 ">
            
                
                  <div className="  " >
                    <Link to="/services/Design">
                    <div className=" card-service design-bg bottomdiv"  >
                      <div className="container h-100">
                        <h4 className="uppercase" style={{ fontSize: "120%", color: "#63fdd8" }}>
                          Design&Visual
                        </h4>
                        <p >
                            {t("Web & Graphic designers")}, 
                            {t(" advertising structure")},{t(" advertising campaigns")}, {t("c.d. authoring")}.
                          
                          
                        </p>
                        <p className="bottombutton"style={{ fontSize: "120%", color: "#63fdd8" }} >{t("View more")}</p>
                      </div>
                    </div>
                    </Link>
                  </div>
               

          </div>
          <div  className="col-11 col-sm-5 col-md-5 col-lg-5 col-xl-4 p-3 ">
            
                  <div className="  " >
                    <Link to="/services/VideoEditing">
                    <div className=" card-service editing-bg bottomdiv"  >
                      <div className="container " >
                        <h4 className="uppercase" style={{ fontSize: "120%", color: "#63fdd8" }}>
                          Editing Audio, Video, Photo.
                        </h4>
                        <p className="col-8 col-sm-12" >
                          Realizzazione video presentazione, Photoshop, montaggio clip, basi musicali.
                          </p>
                        <p  className="bottombutton"style={{ fontSize: "120%", color: "#63fdd8",  }} >{t("View more")}</p>
                      </div>
                    </div>
                    </Link>
                  </div>
                

          </div>
          
          
          <div  className="col-11 col-sm-5 col-md-5 col-lg-5 col-xl-4 p-3 ">
            
                  <div className="  " >
                    <Link >
                    <div className="card-service web3-bg bottomdiv"  >
                      <div className="container">
                        <h4 className="uppercase" style={{ fontSize: "120%", color: "#63fdd8" }}>
                          Web 3.0
                        </h4>
                        <p>
                       {t(" Development of")} Dapps, {t("Nft collection")}, Erc-20 Token, Staking, Farming

                        </p>
                        <p style={{fontSize:"140%"}}>
                        
                        {t("Available as soon as possible")}
                        </p>
                        <div id="works">

                        </div>
                      </div>
                    </div>
                    </Link>
                  </div>
               
             

          </div>
          



        </div>

      </div>


    
  );
}
export default Services;
