

import React from 'react';
function Necessita() {

   

    return (
        <div className="  container  overflow-hidden ">
        <div className="  my-1 row mx-sm-1  row-cols-1  row-cols-lg-2 gx-5 gy-4 justify-content-center">
          <div className='col d-flex'>
          <div className=' web-card  coldev  row justify-content-center'>
            <div  className='didascaliaCard d-flex'><h6>E-commerce</h6></div>
            <div className='col d-flex align-items-center '>
              <h5 className="text-white " > Vendi Online</h5>
            </div>
            <div className='mt-3'>
              <p className='mb-0'>
                Se vendi prodotti o servizi, un sito web ti permette di espandere il tuo mercato.
                Puoi vendere online 24/7, aumentando le tue oppurtunità di guadagno.
              </p>
            </div>
          </div>
          </div>
          
          <div className='col d-flex'>
          <div className='web-card  coldev  row justify-content-center '>
          <div  className='didascaliaCard d-flex'><h6>Accessibilità</h6></div>
            <div className='col d-flex align-items-center '>
            
              <h5 className="text-white" >Adattamento mobile</h5>
            </div>
            <div className='mt-3'>
              <p className='mb-0'>
                Garantisce un'esperienza utente fluida su smartphone e tablet.
                Questo è cruciale dato che sempre più persone navigano e fanno acquisti online tramite dispositivi mobili.</p>
            </div>



          </div>
          </div>
          <div className='col d-flex'>
          <div className='web-card  coldev  row justify-content-center'>
          <div  className='didascaliaCard d-flex'><h6>Insights</h6></div>
            <div className='col d-flex align-items-center '>
              <h5 className="text-white" >Controllo e analisi</h5>
            </div>
            <div className='mt-3'>
              <p className='mb-0'>
              Ti offre strumenti di analisi per monitorare il comportamento dei visitatori.
              Puoi raccogliere dati preziosi per migliorare la tua strategia di businesss e ottimizzare il tuo sito.</p>
            </div>



          </div>
          </div>
          <div className='col d-flex'>

          <div className='web-card  coldev  row justify-content-center'>
          <div  className='didascaliaCard d-flex'><h6>Risorse</h6></div>
            <div className='col d-flex align-items-center '>
            
              <h5 className="text-white" >Informazioni chiave</h5>
            </div>
            <div className='mt-3'>
              <p className='mb-0'>
              La tua pagina web può ospitare una varietà di risorse e contenuti informativi, come guide,articoli
              e FAQ, che aiutano i clienti a comprendere meglio i tuoi prodotti o servizi</p>
            </div>



          </div>
          </div>
          <div className='col d-flex'>
          <div className='web-card col coldev  row justify-content-center '>
          <div  className='didascaliaCard d-flex'><h6>Risorse</h6></div>
            <div className='col d-flex align-items-center '>
              <h5 className="text-white" >Personalizzato</h5>
            </div>
            <div className='mt-3'>
              <p className='mb-0'>
              Ogni sito web è progettato su misura per soddisfare le specifiche esigenze del cliente.
              Questo significa che il design, le funzionalità e i contenuti sono personalizzati per riflettere i tuoi
              obiettivi speicifici</p>
            </div>



          </div>
          </div>
          
          




        </div>
      </div>



    );
}
export default Necessita;